import { patternsForReplacement } from 'constants/global';
import isEqual from 'lodash/isEqual';
import { visualisationTypes } from 'modules/workspace/components/WorkAreaSpace/constants';
import { ColumnRef, OrderBy } from 'node-sql-parser';
import { createSelector } from 'reselect';
import { getAstOfSourceById, getAstOfVisualisationById } from 'store/reducers/ast/getters';
import { getActiveBoardElement } from 'store/reducers/board/getters';
import { getAstEnabledFiltersByParams } from 'store/reducers/filters/getters';
import { getPageIds } from 'store/reducers/projectPages/getters';
import { getDefaultModelId } from 'store/reducers/projectSettings/getters';
import {
  CodeEditorDataInterface,
  DefaultDataSettingsInterface,
  DefaultVisualisationOptionsType,
  getSqlRequestForGroupingRowTableInterface,
  SortConditionColorInterface,
  SourceEnum,
  TableDataSettings,
  TableVisualisationType,
  TextVisualisationType,
  VisualisationType,
} from 'store/reducers/visualisations/types';
import { getState } from 'store/utils';
import { AST } from 'types/ast';
import { formatSql } from 'utils/SQL/formatSQL';
import {
  defaultSelectAST,
  generateBasicColumn,
  generateUnionWhereIn,
  getColumnsWithoutSelect,
  getWhereString,
  sqlParser,
} from 'utils/SQL/genereteAst';
import { sortByYCoordinateFn } from 'utils/utils';
import { getCustomAliasName } from 'utils/formatting';
import { VisualisationAstInterface } from 'store/reducers/ast/types';
import {
  backgroundByValueAlias,
  colorValueByAlias,
  dynamicMarkerColorAlias,
  dynamicMarkerFormAlias,
  getVisualisationFieldName,
  hyperLinksAlias,
  imageLinksAlias,
} from './constants';

export const getVisualisationsStore = createSelector(getState, (state) => state.visualisations);

export const getAlreadyLoadedContent = createSelector(getVisualisationsStore, (state) => state.alreadyLoadedContent);

export const getVisualisations = createSelector(getVisualisationsStore, (state) => state.visualisations);

export const getAllPreviewTemplateVisualisations = createSelector(
  getVisualisationsStore,
  (state) => state.allPreviewTemplateVisualisations,
);

export const getServerStateOfVisualisations = createSelector(
  getVisualisationsStore,
  (state) => state.serverStateOfVisualisations,
);
export const getHasChangesOfVisualisations = createSelector(
  [getServerStateOfVisualisations, getVisualisations],
  (serverState, currentState) => (serverState === null ? false : !isEqual(serverState, currentState)),
);

export const getVisualisationIdsByPage = createSelector(getVisualisationsStore, (state) => state.visualisationsByPages);

export const getArrayVisualisations = createSelector(
  getVisualisations,
  (visualisations) => (Object.values(visualisations) as DefaultVisualisationOptionsType[]) || [],
);

export const getVisualisationById = (id: string) => createSelector(getVisualisations, (visualisations) => visualisations[id]);

export const getVisualisationIdsByPageId = (pageId: string) =>
  createSelector(getVisualisationIdsByPage, (pages) => pages[pageId]);

export const getVisualisationIdsByPageAsArray = (pageId?: string) =>
  createSelector(getState, (state) => Array.from(getVisualisationIdsByPageId(pageId || '')(state) || []));

export const getVisualisationsAlreadyLoaded = (pageId: string) =>
  createSelector(getAlreadyLoadedContent, (alreadyLoadedContent) => alreadyLoadedContent.visualisations.has(pageId));

export const getWidgetsByPageId = (pageId?: string) =>
  createSelector([getState, getVisualisationIdsByPageAsArray(pageId)], (state, visualisationIds) =>
    visualisationIds
      .reduce<DefaultVisualisationOptionsType[]>((result, id) => {
        const visualisation = getVisualisationById(id)(state);

        if (visualisation) {
          return [...result, visualisation];
        }

        return result;
      }, [])
      .sort(sortByYCoordinateFn),
  );

export const getActiveMediaBlocks = (pageId?: string) =>
  createSelector(getState, (state) => {
    const widgetsArray = getWidgetsByPageId(pageId)(state);

    return widgetsArray.filter(({ visualisationType }) => visualisationType === 'text') as TextVisualisationType[];
  });

export const getActiveVisualisations = (pageId?: string) =>
  createSelector(getState, (state) => {
    const visualisationsArray = getWidgetsByPageId(pageId)(state);

    return visualisationsArray.filter(({ visualisationType }) => visualisationType !== 'text');
  });

export const getVisualisationsLoadingList = createSelector(getVisualisationsStore, (state) => state.visualisationsLoadingList);
export const getVisualisationsErrorList = createSelector(getVisualisationsStore, (state) => state.visualisationsErrorsList);

export const isVisualisationLoading = (id: string) =>
  createSelector(getVisualisationsLoadingList, (visualisationsLoadingList) => !!visualisationsLoadingList[id]);

export const isVisualisationsLoading = createSelector(getVisualisationsStore, (state) => state.visualisationsLoading);

export const getActiveVisualisationSettings = createSelector([getState, getActiveBoardElement], (state, activeBoardElement) => {
  const activeVisualisation = activeBoardElement && getVisualisations(state)[activeBoardElement];

  if (activeVisualisation) {
    const { viewSettings, backgroundImagesSettings, dataSettings, sqlData, events, id, visualisationType, positionConfig } =
      activeVisualisation;

    return { viewSettings, backgroundImagesSettings, dataSettings, sqlData, events, id, visualisationType, positionConfig };
  }
});

export const getVisualisationData = createSelector(getVisualisationsStore, (state) => state.visualisationData);

export const getVisualisationDataById = (id: string) =>
  createSelector(getVisualisationData, (visualisationData) => visualisationData[id]);

export const getWidgetsByPages = createSelector([getState, getPageIds], (state, pageIds) =>
  pageIds.reduce((results, pageId) => {
    if (getVisualisationsAlreadyLoaded(pageId)(state)) {
      return [...results, { pageId, visualisations: getWidgetsByPageId(pageId)(state) }];
    }

    return results;
  }, [] as Array<{ pageId: string; visualisations: DefaultVisualisationOptionsType[] }>),
);

const orderByColumns = ({
  visualisation,
  astParts,
}: {
  visualisation?: DefaultVisualisationOptionsType;
  astParts: VisualisationAstInterface;
}) => {
  if (!visualisation) {
    return null;
  }
  const visual = visualisation as TableVisualisationType;

  const orderByColumnsTableIds = visual?.dataSettings?.orderBy.map((el) => el.columnName);
  const orderByColumnsTable: OrderBy[] = [...astParts.incisions, ...astParts.indicators]
    .filter((column) => column.as && orderByColumnsTableIds.includes(column.as))
    .map((column) => ({
      type: visual?.dataSettings?.orderBy.filter((el) => el.columnName === column.as)[0]?.type,
      expr: column.expr,
    }));

  return orderByColumnsTable;
};

const sortConditionColor = ({ dataSettings, alias, valueCondition }: SortConditionColorInterface) => {
  const tableDataSettings = dataSettings as TableDataSettings;
  let hasCondition = false;

  const allColumns = [...tableDataSettings.incisions, ...tableDataSettings.indicators],
    customWhere = allColumns.reduce<string>((total, column, index) => {
      const sqlCondition = column.settings?.properties?.[valueCondition]?.byCondition?.sqlCondition;
      if (sqlCondition) {
        hasCondition = true;
      }
      return total + `${sqlCondition || null}` + (index !== allColumns.length - 1 ? ', ' : '');
    }, '');

  return hasCondition
    ? [
        {
          as: alias,
          expr: {
            value: `array(${customWhere})`,
          },
        },
      ]
    : [];
};
const sortConditionDynamicMarker = ({ dataSettings }: { dataSettings: DefaultDataSettingsInterface }) => {
  const tableDataSettings = dataSettings as TableDataSettings;
  const conditionsDynamicMarker = [];
  let hasConditionInColors = false;
  let hasConditionInForm = false;

  const customWhereColors = tableDataSettings.indicators.reduce<string>((total, column, index) => {
    const {
      sql: {
        colorMarker: {
          byCondition: { sqlCondition },
        },
      },
    } = column.settings.dynamicsMarkerSettings.settingsMarker;

    if (sqlCondition) {
      hasConditionInColors = true;
    }

    return total + `${sqlCondition || null}` + (index !== tableDataSettings.indicators.length - 1 ? ', ' : '');
  }, '');

  const customWhereForms = tableDataSettings.indicators.reduce<string>((total, column, index) => {
    const {
      sql: {
        formMarker: {
          byCondition: { sqlCondition },
        },
      },
    } = column.settings.dynamicsMarkerSettings.settingsMarker;

    if (sqlCondition) {
      hasConditionInForm = true;
    }

    return total + `${sqlCondition || null}` + (index !== tableDataSettings.indicators.length - 1 ? ', ' : '');
  }, '');

  hasConditionInColors &&
    conditionsDynamicMarker.push({
      as: dynamicMarkerColorAlias,
      expr: {
        value: `array(${customWhereColors})`,
      },
    });

  hasConditionInForm &&
    conditionsDynamicMarker.push({
      as: dynamicMarkerFormAlias,
      expr: {
        value: `array(${customWhereForms})`,
      },
    });

  return conditionsDynamicMarker;
};

const getHyperLinks = ({ dataSettings }: { dataSettings: DefaultDataSettingsInterface }) => {
  const tableDataSettings = dataSettings as TableDataSettings;

  return [...tableDataSettings.incisions, ...tableDataSettings.incisionsInHeader, ...tableDataSettings.indicators].reduce<
    AST.BasicColumn[]
  >((total, column) => {
    const {
      name,
      fieldName: fieldNameColumn,
      settings: { nameFromDatabase, hyperLink, imagesSettings },
    } = column;

    const nameColumn = getVisualisationFieldName({
      fieldName: fieldNameColumn,
      name,
      nameFromDatabase,
    });

    if (!hyperLink) {
      return total;
    }

    const {
      source: { type, value },
      isActive: isActiveHyperLink,
    } = hyperLink;

    const {
      isActive,
      source: {
        type: typeImageLink,
        value: { fieldName: fieldNameImage },
      },
    } = imagesSettings;
    const { fieldName: fieldNameHyperLink } = value;
    const aliasHyperLink = getCustomAliasName({ nameIncision: nameColumn, alias: hyperLinksAlias });
    const aliasImageLink = getCustomAliasName({ nameIncision: nameColumn, alias: imageLinksAlias });

    if (type === SourceEnum.DATABASE && fieldNameHyperLink && isActiveHyperLink) {
      total.push(generateBasicColumn({ fieldName: fieldNameHyperLink, alias: aliasHyperLink }));
    }
    if (typeImageLink === SourceEnum.DATABASE && fieldNameImage && isActive) {
      total.push(generateBasicColumn({ fieldName: fieldNameImage, alias: aliasImageLink }));
    }

    return total;
  }, []);
};

const getHyperLinksByCondition = ({ dataSettings }: { dataSettings: DefaultDataSettingsInterface }) => {
  const tableDataSettings = dataSettings as TableDataSettings;

  return [...tableDataSettings.incisions, ...tableDataSettings.incisionsInHeader, ...tableDataSettings.indicators].reduce<
    AST.ValueCondition[]
  >((total, column) => {
    const {
      name,
      fieldName: fieldNameColumn,
      settings: { nameFromDatabase, hyperLink, imagesSettings },
    } = column;

    const nameColumn = getVisualisationFieldName({
      fieldName: fieldNameColumn,
      name,
      nameFromDatabase,
    });

    const {
      source: {
        type,
        value: { sqlCondition, textLink },
      },
      isActive: isActiveHyperLink,
    } = hyperLink;

    const {
      isActive,
      source: {
        type: typeImageLink,
        value: { sqlCondition: sqlConditionImage, textLink: textLinkImage },
      },
    } = imagesSettings;

    const aliasHyperLink = getCustomAliasName({ nameIncision: nameColumn, alias: hyperLinksAlias });
    const aliasImageLink = getCustomAliasName({ nameIncision: nameColumn, alias: imageLinksAlias });

    if (type === SourceEnum.BYCONDITION && sqlCondition && isActiveHyperLink) {
      total.push({
        as: aliasHyperLink,
        expr: {
          value: sqlCondition,
        },
      });
    }
    if (type === SourceEnum.MANUAL && textLink && isActiveHyperLink) {
      total.push({
        as: aliasHyperLink,
        expr: {
          value: `'${textLink}'`,
        },
      });
    }

    if (typeImageLink === SourceEnum.MANUAL && textLinkImage && isActive) {
      total.push({
        as: aliasImageLink,
        expr: {
          value: `'${textLinkImage}'`,
        },
      });
    } else if (typeImageLink === SourceEnum.BYCONDITION && sqlConditionImage && isActive) {
      total.push({
        as: aliasImageLink,
        expr: {
          value: sqlConditionImage,
        },
      });
    }

    return total;
  }, []);
};

/* AST */

export const getAstForSqlGenerationQueryById = (id: string) =>
  createSelector([getState, getDefaultModelId], (state, defaultModelId) => {
    const visualisation = getVisualisationById(id)(state),
      pageId = visualisation?.pageId,
      visualizationId = visualisation?.id || '',
      usingFilter = visualisation?.events?.isReactingToFilter,
      isInfluenceItself = visualisation?.events?.filterSettings?.isInfluenceItself,
      excludeVisualisationIds = isInfluenceItself ? [] : [visualisation?.id || ''],
      modelId = visualisation?.dataSettings?.modelId,
      modelIdValue = modelId || defaultModelId || '',
      isTableVisualisation = visualisation?.visualisationType === 'table',
      conditionBackgroundColor = isTableVisualisation
        ? sortConditionColor({
            dataSettings: visualisation.dataSettings,
            alias: backgroundByValueAlias,
            valueCondition: 'backgroundColorBy',
          })
        : [],
      conditionFontColor = isTableVisualisation
        ? sortConditionColor({
            dataSettings: visualisation.dataSettings,
            alias: colorValueByAlias,
            valueCondition: 'fontColorBy',
          })
        : [],
      hyperLinks = isTableVisualisation
        ? getHyperLinks({
            dataSettings: visualisation.dataSettings,
          })
        : [],
      hyperLinksByCondition = isTableVisualisation
        ? getHyperLinksByCondition({
            dataSettings: visualisation.dataSettings,
          })
        : [],
      markersDynamicByCondition = isTableVisualisation
        ? sortConditionDynamicMarker({
            dataSettings: visualisation.dataSettings,
          })
        : [],
      astParts = getAstOfVisualisationById(id)(state),
      orderByColumnsTable = isTableVisualisation ? orderByColumns({ visualisation, astParts }) : null,
      whereAstData = usingFilter
        ? getAstEnabledFiltersByParams(pageId || '', modelIdValue, excludeVisualisationIds, visualizationId)(state)
        : [],
      from = getAstOfSourceById(modelIdValue)(state)?.ast || null;

    return {
      ...astParts,
      whereAstData: generateUnionWhereIn(whereAstData),
      from,
      modelIdValue,
      orderByColumnsTable,
      conditionBackgroundColor,
      conditionFontColor,
      hyperLinks,
      hyperLinksByCondition,
      markersDynamicByCondition,
    };
  });

/* SQL String */

export const getSqlRequestById = createSelector(getState, (state) => (id: string) => {
  const {
    groupBy,
    indicators,
    incisions,
    whereAstData,
    serviceValues,
    variables,
    images,
    limit,
    from,
    orderByColumnsTable,
    activeIncisionIndex,
    conditionBackgroundColor,
    conditionFontColor,
    hyperLinks,
    hyperLinksByCondition,
    markersDynamicByCondition,
    filtersAndGroups: { groupby, where, limit: filtersAndGroupLimit, orderby, having },
  } = getAstForSqlGenerationQueryById(id)(state);

  const incisionsColumns = activeIncisionIndex !== null ? [incisions[activeIncisionIndex]] : incisions;

  const columns = [...incisionsColumns, ...indicators, ...variables, ...images, ...serviceValues];
  const totalColumns = [
    ...columns,
    ...conditionFontColor,
    ...conditionBackgroundColor,
    ...hyperLinks,
    ...hyperLinksByCondition,
    ...markersDynamicByCondition,
  ];
  const groupByHyperLinks = hyperLinks.map((column) => column.expr);

  let sqlRequest: undefined | string;

  try {
    if (from !== null && columns.length) {
      const unionGroupBy = Array.isArray(groupby)
          ? [...groupBy, ...groupby, ...groupByHyperLinks]
          : [...groupBy, ...groupByHyperLinks],
        unionWhere = where ? generateUnionWhereIn([where, whereAstData].filter((where) => !!where)) : whereAstData,
        allOrderBy = Array.isArray(orderby) && Array.isArray(orderByColumnsTable) ? [...orderByColumnsTable, ...orderby] : null;

      sqlRequest = sqlParser.sqlify({
        ...defaultSelectAST,
        columns: totalColumns,
        groupby: unionGroupBy,
        where: unionWhere,
        limit: filtersAndGroupLimit || limit,
        from,
        orderby: allOrderBy || orderByColumnsTable || orderby,
        having,
      });

      sqlRequest = sqlRequest
        ? Object.entries(patternsForReplacement).reduce<string>(
            (value, [replacement, pattern]) => value.replace(pattern, replacement),
            sqlRequest,
          )
        : undefined;

      return sqlRequest;
    }
  } finally {
    return sqlRequest;
  }
});
export const getSqlRequestForGroupingRowTable = createSelector(
  getState,
  (state) =>
    ({
      id,
      columnNextIndex,
      parentsChain,
      limitData,
      incisionsInHeaderNames,
      isPivotTable,
    }: getSqlRequestForGroupingRowTableInterface) => {
      const {
        groupBy,
        indicators,
        incisions,
        whereAstData,
        serviceValues,
        variables,
        images,
        limit,
        from,
        orderByColumnsTable,
        conditionBackgroundColor,
        conditionFontColor,
        hyperLinks,
        hyperLinksByCondition,
        markersDynamicByCondition,
        filtersAndGroups: { groupby, where, limit: filtersAndGroupLimit, orderby, having },
      } = getAstForSqlGenerationQueryById(id)(state);

      if (!incisions.length) {
        return;
      }

      const incisionsInColumns = incisions.filter((incision) => incision.as && !incisionsInHeaderNames.includes(incision.as));
      const groupingIncisions = columnNextIndex ? incisionsInColumns.slice(0, columnNextIndex) : incisionsInColumns;

      const groupByForGroupTable: ColumnRef[] = columnNextIndex
        ? (groupingIncisions.map((incision) => incision.expr) as ColumnRef[])
        : groupBy;
      const hyperLinksByConditionCustom = isPivotTable
        ? hyperLinksByCondition.filter((el) => {
            const validIncisionNames = incisionsInHeaderNames.flatMap((incisionName) => [
              getCustomAliasName({ nameIncision: incisionName, alias: hyperLinksAlias }),
              getCustomAliasName({ nameIncision: incisionName, alias: imageLinksAlias }),
            ]);
            const validGroupingIncisions = groupingIncisions.flatMap((incision) => {
              if (incision.as) {
                return [
                  getCustomAliasName({ nameIncision: incision.as, alias: hyperLinksAlias }),
                  getCustomAliasName({ nameIncision: incision.as, alias: imageLinksAlias }),
                ];
              }
            });
            return !(validIncisionNames.includes(el.as) || validGroupingIncisions.includes(el.as));
          })
        : hyperLinksByCondition;
      const columns = [...groupingIncisions, ...indicators, ...variables, ...images, ...serviceValues];
      const totalColumns = [
        ...columns,
        ...conditionFontColor,
        ...conditionBackgroundColor,
        ...hyperLinks,
        ...hyperLinksByConditionCustom,
        ...markersDynamicByCondition,
      ];
      const groupByHyperLinks = hyperLinks.map((column) => column.expr);
      const orderByColumnsTableForGroup =
        orderByColumnsTable &&
        groupingIncisions &&
        orderByColumnsTable?.filter((el) => {
          const columnsOrderBy = columns as unknown as OrderBy[];
          return columnsOrderBy.some((incision) => {
            const column = incision.expr.column || incision.expr?.args?.expr?.column;
            const elColumn = el.expr.column || el.expr?.args?.expr?.column;
            return column === elColumn;
          });
        });
      const customWhere =
        groupingIncisions && parentsChain
          ? generateUnionWhereIn(
              groupingIncisions.reduce<AST.WhereLike[]>((acc, incision, index) => {
                if (index < groupingIncisions.length - 1 && parentsChain[index]) {
                  const value = parentsChain[index];
                  acc.push({
                    type: 'binary_expr',
                    operator: '=',
                    left: incision.expr as ColumnRef,
                    right: { type: 'string', value: value ? parentsChain[index] : '' },
                  } as AST.WhereLike);
                }
                return acc;
              }, []),
            )
          : null;

      let sqlRequest: undefined | string;

      if (from !== null && columns.length) {
        const unionGroupBy = Array.isArray(groupby)
            ? [...groupByForGroupTable, ...groupby, ...groupByHyperLinks]
            : [...groupByForGroupTable, ...groupByHyperLinks],
          unionWhere = generateUnionWhereIn([where, whereAstData, customWhere].filter((where) => !!where)),
          allOrderBy =
            Array.isArray(orderby) && Array.isArray(orderByColumnsTableForGroup)
              ? [...orderByColumnsTableForGroup, ...orderby]
              : null;

        sqlRequest = sqlParser.sqlify({
          ...defaultSelectAST,
          columns: totalColumns,
          groupby: unionGroupBy,
          where: unionWhere,
          limit: limitData || filtersAndGroupLimit || limit,
          from,
          orderby: allOrderBy || orderByColumnsTableForGroup || orderby,
          having,
        });

        sqlRequest = sqlRequest
          ? Object.entries(patternsForReplacement).reduce<string>(
              (value, [replacement, pattern]) => value.replace(pattern, replacement),
              sqlRequest,
            )
          : undefined;

        return sqlRequest;
      }
    },
);

export const getSqlRequestForHeaderTable = createSelector(
  getState,
  (state) =>
    ({ id, incisionsInHeaderNames, limitData }: getSqlRequestForGroupingRowTableInterface) => {
      const {
        incisions,
        whereAstData,
        orderByColumnsTable,
        from,
        filtersAndGroups: { groupby, where, limit: filtersAndGroupLimit, orderby, having },
        hyperLinksByCondition,
      } = getAstForSqlGenerationQueryById(id)(state);

      const incisionsInHeaders = incisions.filter((incision) => incision.as && incisionsInHeaderNames.includes(incision.as));

      if (!incisionsInHeaders.length) {
        return;
      }

      const GroupByIncisionsInHeader = incisionsInHeaders.map((incision) => incision.expr) as ColumnRef[];
      const hyperLinksByConditionCustom = hyperLinksByCondition.filter((el) =>
        incisionsInHeaderNames
          .flatMap((el) => [
            getCustomAliasName({
              nameIncision: el,
              alias: hyperLinksAlias,
            }),
            getCustomAliasName({
              nameIncision: el,
              alias: imageLinksAlias,
            }),
          ])
          .includes(el.as),
      );
      const columns = [...incisionsInHeaders, ...hyperLinksByConditionCustom];
      const orderByColumnsTableForGroup =
        orderByColumnsTable &&
        orderByColumnsTable.filter((el) => {
          const columnsOrderBy = columns as unknown as OrderBy[];
          return columnsOrderBy.some((incision) => {
            const column = incision.expr.column || incision.expr?.args?.expr?.column;
            const elColumn = el.expr.column || el.expr?.args?.expr?.column;
            return column === elColumn;
          });
        });

      let sqlRequest: undefined | string;

      if (from !== null && columns.length) {
        const unionGroupBy = Array.isArray(groupby) ? [...GroupByIncisionsInHeader, ...groupby] : GroupByIncisionsInHeader;
        const unionWhere = generateUnionWhereIn([where, whereAstData].filter((where) => !!where));

        sqlRequest = sqlParser.sqlify({
          ...defaultSelectAST,
          columns,
          groupby: unionGroupBy,
          where: unionWhere,
          limit: limitData || filtersAndGroupLimit || null,
          from,
          orderby: orderByColumnsTableForGroup || orderby,
        });

        sqlRequest = sqlRequest
          ? Object.entries(patternsForReplacement).reduce<string>(
              (value, [replacement, pattern]) => value.replace(pattern, replacement),
              sqlRequest,
            )
          : undefined;

        return sqlRequest;
      }
    },
);

export const getAllSQLRequestsByIdForExport = createSelector(getState, (state) => (id: string) => {
  const visualisation = id && getVisualisationById(id)(state);

  if (!visualisation) {
    return;
  }

  const { viewSettings, dataSettings, visualisationType } = visualisation;

  const nameFile = viewSettings?.header?.isShow ? viewSettings?.header?.text : undefined;

  const { isRealData } = dataSettings;

  const isWidgetTableData = visualisationType ? visualisationTypes.includes(visualisationType as VisualisationType) : false;

  if (!isWidgetTableData || !isRealData) {
    return;
  }

  const {
    indicators,
    incisions,
    whereAstData,
    limit,
    from,
    modelIdValue,
    filtersAndGroups: { where, limit: filtersAndGroupLimit, orderby, having },
  } = getAstForSqlGenerationQueryById(id)(state);

  const columns = [...incisions, ...indicators];

  const unionWhere = where ? generateUnionWhereIn([where, whereAstData].filter((where) => !!where)) : whereAstData;

  if (from !== null && columns.length > 0) {
    let sqlRequest: string = sqlParser.sqlify({
      ...defaultSelectAST,
      columns,
      groupby: incisions.map((incision) => incision.expr) as ColumnRef[],
      where: unionWhere,
      limit: filtersAndGroupLimit || limit,
      from,
      orderby,
      having,
    });

    sqlRequest = Object.entries(patternsForReplacement).reduce<string>(
      (value, [replacement, pattern]) => value.replace(pattern, replacement),
      sqlRequest,
    );

    return { queries: { name: 'data', query: sqlRequest, modelId: modelIdValue }, nameFile };
  }
});

export const getWhereQueryById = createSelector(getState, (state) => (id: string) => {
  const { whereAstData } = getAstForSqlGenerationQueryById(id)(state);

  let whereQuery: undefined | string;

  try {
    whereQuery = getWhereString(whereAstData);
  } finally {
    return whereQuery;
  }
});

export const getCodeEditorDataById = (id: string) =>
  createSelector(getState, (state) => {
    const { indicators, incisions } = getAstForSqlGenerationQueryById(id)(state),
      visualisation = getVisualisationById(id)(state);

    const filterAndGroupSqlString = visualisation ? visualisation?.sqlData?.filterAndGroupRequest : undefined;

    let indicatorSqlString: string | undefined, incisionSqlString: string | undefined;

    try {
      indicatorSqlString = getColumnsWithoutSelect(indicators);
      incisionSqlString = getColumnsWithoutSelect(incisions);
    } finally {
      return {
        indicatorSqlString: formatSql(indicatorSqlString),
        incisionSqlString: formatSql(incisionSqlString),
        filterAndGroupSqlString: formatSql(filterAndGroupSqlString),
      } as CodeEditorDataInterface;
    }
  });
